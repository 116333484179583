import Vue from 'vue'

const module = 'RichTexts'

export default {
  getByName(name) {
    return Vue.prototype.$http.get(`/api/services/app/${module}/GetAll?NameFilter=${name}`)
  },

  getAll(params = '') {
    return Vue.prototype.$http.get(`/api/services/app/${module}/GetAll?${params}`)
  },

  setItem(item) {
    return Vue.prototype.$http.post(`/api/services/app/${module}/CreateOrEdit`, item)
  },

  getItem(id) {
    return Vue.prototype.$http.get(`/api/services/app/${module}/GetRichTextForEdit?id=${id}`)
  },

  delItem(id) {
    return Vue.prototype.$http.delete(`/api/services/app/${module}/Delete?id=${id}`)
  },
}
